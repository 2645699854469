.formulaire td {
  padding: 10px;
}

.form-control:not(select) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #61d194 !important;
}
.form-control {
  margin: inherit;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

label.label-required-hint::after {
  content: " *";
  color: red;
}

.form_table_layout {
  display: grid;
  align-items: center;
  margin: 2rem auto 1rem auto;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  max-width: 50rem;

  label {
    text-align: right;
  }

  input[type="checkbox"],
  input[type="radio"] {
    justify-self: left;
  }

  h5,
  button {
    grid-column: 1 / 3;
    justify-self: center;
  }
}
