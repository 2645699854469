@media (min-width: 640px) {
  @import "page_paneliste_paneliste.scss";
  @import "page_paneliste_signalement.scss";
}

.styles-paneliste {
  @media (min-width: 640px) {
    @import "./index_enqueteur_paneliste.scss";
  }

  @media (max-width: 640px) {
    #div_logo {
      width: 100%;
      background-color: white;
      text-align: center;
      border-bottom: 1px solid #ccc;
    }
    #div_logo img {
      width: 18%;
    }
    .sidenav {
      width: 100%;
      z-index: 1;
      left: 0;
      background-color: #61d194;
      overflow-x: hidden;
      padding-top: 20px;
    }
    .wrapper .sidebar ul li a {
      color: #ebeadb;
      display: block;
      text-align: center;
    }
    .wrapper .sidebar ul li {
      border-bottom: 1px solid #3c82b1;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      border-top: 1px solid rgba(255, 255, 255, 0.05);
    }
    .sidenav a,
    .dropdown-btn {
      padding: 6px 8px 6px 10px;
      text-decoration: none;
      color: #818181;
      display: block;
      border: none;
      background-color: none;
      width: 100%;
      text-align: left;
      cursor: pointer;
      outline: none;
    }
    ion-icon {
      margin-right: 10px;
      padding-right: 6px;
      font-size: 20px;
      margin: auto;
    }
    h1 {
      font-size: 1.1em;
    }
    .table tr td {
      font-size: 2vw;
      border: #ddd 1px solid;
    }

    #map {
      margin-bottom: 0;
    }
  }
}
