.page_paneliste_signalement {
  .left {
    width:50%;
    float:left;
  }

  .alignright {
    text-align: right;
  }

  .btn-green {
    background-color: #fff;
    box-shadow: 0 0 3px 0 rgba(97, 209, 148, 0.5) !important;
    border: solid 1px #61d194 !important;
    color: #61d194 !important;
  }
  .mf {
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    width: 95%;
  }
  .monlabel {
    float: left;
    margin-top: 10px;
    margin-right: 40px;
    font-weight: bold;
    color: #61d194 !important;
  }
  .moninput {
    margin-left: 20px;
    margin-top: 10px !important;
    padding: 10px;
  }

  .alerte {
    width: 584px;
    height: 16px;
    margin: 12px 280px 45px 1px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #F00;
  }
}
