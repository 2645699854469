.page-enqueteur-connection-parameter {
  display: flex;
  flex-direction: column;

  fieldset {
    display: flex;
    align-items: center;
    margin: 0.5em;
  }
  label {

    margin: 0;
  }

  .connexion-parameter__bloc {
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 1rem;
  }

  .switch {
    /* Inspired from https://www.w3schools.com/howto/howto_css_switch.asp */
    --switch-width: 10em;
    --switch-height: 2em;
    --switch-padding: 0.25em;
    --slider-size: calc(
      var(--switch-height) - var(--switch-padding) - var(--switch-padding)
    );
    position: relative;
    display: inline-block;
    width: var(--switch-width);
    height: 2em;

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: white;
      background-color: #989797;
      transition: 0.4s;
    }

    .slider__label {
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .slider:before {
      position: absolute;
      content: "";
      height: var(--slider-size);
      width: var(--slider-size);
      left: var(--switch-padding);
      bottom: var(--switch-padding);
      background-color: white;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #337ab7;
    }

    input:checked + .slider > .slider__label--activated {
      display: block;
    }
    input:not(:checked) + .slider > .slider__label--desactivated {
      display: block;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      transform: translateX(
        calc(
          var(--switch-width) - var(--slider-size) - var(--switch-padding) -
            var(--switch-padding)
        )
      );
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
