.sidenav {
  height: 100%;
  width: 100%;
  z-index: $z-index-1000;
  left: 0;
  overflow-x: hidden;

  ion-icon {
    margin: 0;
  }

  ul li {
    border-bottom: 1px solid rgba(0,0,0,0.05);
    border-top: 1px solid rgba(255,255,255,0.05);
  }

  ul li a {
    color: #ebeadb;
    display: flex;
    align-items: center;
    padding: 10px 8px 10px 10px;

    &:hover {
      background-color: white;
      color: #61d194;
    }
  }

  @import "./common_sidenav_dropdown.scss";
}

/* Some media queries for responsiveness */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 16px;
  }
}

