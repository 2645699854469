@import "bootstrap-reboot";

// https://fontawesome.com/docs/web/use-with/scss
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts/";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

@import "~@fontsource/montserrat/latin.css";


@import './constantes.scss';

@import './shared.scss';
@import './shared_header.scss';
@import './shared_language_view.scss';

@import '../node_modules/video.js/dist/video-js.min.css';
@import './videojs.scss';

@import './enqueteur.scss';
@import './paneliste.scss';
@import './page-index.scss';
@import './mcd.scss';

@import "~datatables.net-dt/css/jquery.dataTables.min.css";
