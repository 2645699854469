.red {
  color: red;
}
.orange {
  color: orange;
}
.onglet {
  width: 100%;
  float: left;
}

.ongletc {
  width: 100%;
  float: left;
}
#outil {
  padding: 5px;
}

.onglet p {
  float: left;
  background-color: #e39239;
  background-color: #61d194;
  padding: 7px;
  border: solid 1px #747070;
  border-radius: 6px;
  font-size: 14px;
  margin: 3px;
}
.select {
  background-color: #243a6d !important;
  color: white;
}
.onglet a {
  color: #243a6d;
  font-size: 12px;
  font-style: italic;
}

.trespetit {
  font-size: 12px !important;
}
