.page_paneliste_paneliste {
  #content > .col-xs-10 > .box,
  #content > .col-xs-10 > .box > .box-body {
    background-color: unset;
    margin: unset;
    box-shadow: unset;
    border-radius: unset;
    min-height: unset;
  }

  .box-body {
    .div-message
    {
      margin-top:40px!important;
      text-align:left;
      margin:auto;
      padding: 26px 8px 25px 26px;
      border-radius: 15px;
      background-color: rgba(97, 209, 148, 0.1);
    }

    .box-footer {
      background-color: #fff;
      width: 1101px;
      margin: 14px 16px 18px 18px;
      border-radius: 15px;
      padding: 2rem;
      box-shadow: unset;
      border-top: unset;
    }
  }

}
