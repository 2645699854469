:root {
  --red: #ef545a;
  --blue: #434343;
  --clear-blue: #000;
  --green: #61d194;
}

body {
  background-color: rgba(97, 209, 148, 0.04);
  font-family: "Montserrat", sans-serif;
}

ul, ol {
  list-style: none;
}

.green {
  color: var(--green);
}

.erreur {
  color: #ca5815;
}

.floatleft {
  float: left;
}

.pull-right {
  float: right !important;
}

.cursor {
  cursor: pointer;
}

ion-icon {
  margin-right: 10px;
  padding-right: 6px;
  font-size: 22px;
  margin: auto;
}

#map {
  margin-bottom:30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .lds-hourglass {
    font-size: 10rem;
  }
}

// START https://loading.io/css/
// =============================================================================
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 0;
  box-sizing: border-box;
  border: 0.5em solid var(--color-green);
  border-color: var(--color-green) transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
// END https://loading.io/css/
// =============================================================================
