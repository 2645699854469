.sidebar {
  width: var(--sidebar-width);
  height: 100%;
  position: fixed;
  background-color: #61D194;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%) !important;
  font-size: 15px;
  z-index: $z-index-1000;

  #div_logo {
    width: 100%;
    background-color: white;
    text-align: center;
    height: 72px;
    display: flex;
    align-items: center;
    a {
      width: 100%;
    }
  }

  #logo {
    width: 60%;
  }

  @import './common_sidenav.scss';
}
