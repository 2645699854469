@import './constantes.scss';

.language-container {
  position: relative;

  .language-button {
    padding: 0.33rem 1.33rem;
    font-size: 1.33rem;

    i {
      color: black;
      &:hover {
        color: #61d194;
      }
    }
  }

  .language-menu {
    opacity: 0;
    visibility: hidden;
    z-index: $z-index-1000;
    position: absolute;
    right: 0.5rem;
    transition: visibility 0.25s, opacity 0.25s ease-in-out;
    margin-top: 1.33rem;
    padding: 1.33rem;
    background-color: #00d191;
    width: max-content;

    a {
      color: #fff;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &:before {
      right: 1.67rem;
      content: "";
      position: absolute;
      right: 3.33rem;
      top: -1.33rem;
      border-bottom: 1.33rem solid;
      border-left: 2rem solid transparent;
      border-bottom-color: #00d191;
    }

    li {
      padding: 0.33rem 0;
    }

    .language-active {
      font-weight: bold;
    }
  }
}
