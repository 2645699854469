.dropdown-btn {
  text-decoration: none;

  .fa-angle-left {
    width: 25px;
    margin-left: auto;
  }

  &.active .fa-angle-left {
    transform: rotate(-90deg);
    margin-right: 6px;
    margin-bottom: 1rem;
  }
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  padding-left: 8px;

  &.niv1 {
    margin-left: 25px;
  }

  &.niv1 a {
    font-size: 14px;
  }

  &.niv2 {
    margin-left: 5px;
  }

  &.niv2 a {
    font-size: 12x;
  }
}

