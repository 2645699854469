#form_mcd td, th{
  margin:10px;
  padding:5px;
  text-align: center;
}
.little {
  width:20%!important;
  float:left;
  //padding:10px!important;
  margin:20px;
}
.little2 {
  width:10%!important;
  float:left;
  height:35px;
}
.little3 {
  width:20%!important;
  float:left;
  height:35px;
  margin:10px !important;
}
.input_scenario
{
  width:20%;
  float:left;
  height:35px;
  text-align: center;
  margin-top:10px;
}

// Could be put into "./shared.scss"
.font_green {
  color:green;
}
.font_red {
  color:red;
}
// ===
