.wrapper {
  --sidebar-width: 16rem;
  --content-padding: 1.25rem;
  display: flex;
  position: relative;
}

.main_content {
  width: 100%;
  margin-left: var(--sidebar-width);
}

#content {
  --div-content-height: 6.5rem;
  margin-left: var(--content-padding);
  margin-right: var(--content-padding);
  margin-top: var(--div-content-height);
  height: 120%;
  min-height: 500px;
}

.box {
  padding-top: 0.1rem;
  width: 100%;
  height: 31.813rem;
  margin: 0.125rem 0 0;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(97, 209, 148, 0.2);
  background-color: var(--green);
  min-height: 700px;
}

.box-body {
  background-color: #fff;
  border-radius: 15px;
  padding: 10px;
  min-height: 700px;
}

.box-middle {
  background-color: #fff;
  width: 1101px;
  margin: 14px 16px 18px 18px;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 0 10px 0 rgba(97,209,148,.2);
}

.box-footer {
  position: sticky;
  right: 0;
  top: calc(100%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: #fff;
}

.Titre {
  font-size:28px;
  margin-bottom: 20px;
  margin-top: 20px;
  color:#61d194;
  font-weight: bold;
}

ul {
  padding: 0;
  margin: 0;
}
