/** Copied from https://v2.myclauger.com/css/videojs-theme.css **/
/** Used by https://www.npmjs.com/package/video.js */

.vjs-theme-myportal3e {
    --vjs-theme-myportal3e--primary: #61D194;
    --vjs-theme-myportal3e--secondary: #001f5e;

    &.vjs-big-play-button:focus,
    &:hover .vjs-big-play-button {
        background-color: transparent;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' fill='%2361D194'%3E%3Cpath fill-rule='evenodd' d='M44 88C19.738 88 0 68.262 0 44S19.738 0 44 0s44 19.738 44 44-19.738 44-44 44zm0-85C21.393 3 3 21.393 3 44c0 22.608 18.393 41 41 41s41-18.392 41-41C85 21.393 66.607 3 44 3zm16.063 43.898L39.629 60.741a3.496 3.496 0 01-3.604.194 3.492 3.492 0 01-1.859-3.092V30.158c0-1.299.712-2.483 1.859-3.092a3.487 3.487 0 013.604.194l20.433 13.843a3.497 3.497 0 01.001 5.795zm-1.683-3.311L37.946 29.744a.49.49 0 00-.276-.09.51.51 0 00-.239.062.483.483 0 00-.265.442v27.685c0 .262.166.389.265.442.1.053.299.118.515-.028L58.38 44.414A.489.489 0 0058.6 44a.49.49 0 00-.22-.413z'/%3E%3C/svg%3E");
    }

      .vjs-big-play-button {
        width: 88px;
        height: 88px;
        background: none;
        background-repeat: no-repeat;
        background-position: 50%;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' fill='%23fff'%3E%3Cpath fill-rule='evenodd' d='M44 88C19.738 88 0 68.262 0 44S19.738 0 44 0s44 19.738 44 44-19.738 44-44 44zm0-85C21.393 3 3 21.393 3 44c0 22.608 18.393 41 41 41s41-18.392 41-41C85 21.393 66.607 3 44 3zm16.063 43.898L39.629 60.741a3.496 3.496 0 01-3.604.194 3.492 3.492 0 01-1.859-3.092V30.158c0-1.299.712-2.483 1.859-3.092a3.487 3.487 0 013.604.194l20.433 13.843a3.497 3.497 0 01.001 5.795zm-1.683-3.311L37.946 29.744a.49.49 0 00-.276-.09.51.51 0 00-.239.062.483.483 0 00-.265.442v27.685c0 .262.166.389.265.442.1.053.299.118.515-.028L58.38 44.414A.489.489 0 0058.6 44a.49.49 0 00-.22-.413z'/%3E%3C/svg%3E");
        border: none;
        top: 50%;
        left: 50%;
        margin-top: -44px;
        margin-left: -44px;
        color: purple;
    }

          .vjs-big-play-button .vjs-icon-placeholder {
            display: none;
        }

      .vjs-button > .vjs-icon-placeholder:before {
        line-height: 1.55;
    }

      .vjs-control:not(.vjs-disabled):not(.vjs-time-control):hover {
        color: var(--vjs-theme-myportal3e--primary);
        text-shadow: var(--vjs-theme-myportal3e--secondary) 1px 0 10px;
    }

      .vjs-control-bar {
        background: none;
        margin-bottom: 1em;
        padding-left: 1em;
        padding-right: 1em;
    }

      .vjs-play-control {
        font-size: 0.8em;
    }

          .vjs-play-control .vjs-icon-placeholder:before {
            background-color: var(--vjs-theme-myportal3e--secondary);
            height: 1.5em;
            width: 1.5em;
            margin-top: 0.2em;
            border-radius: 1em;
            color: var(--vjs-theme-myportal3e--primary);
        }

          .vjs-play-control:hover .vjs-icon-placeholder:before {
            background-color: var(--vjs-theme-myportal3e--primary);
            color: var(--vjs-theme-myportal3e--secondary);
        }

      .vjs-mute-control {
        display: none;
    }

      .vjs-volume-panel {
        margin-left: 0.5em;
        margin-right: 0.5em;
        padding-top: 0.3em;
    }

          .vjs-volume-bar.vjs-slider-horizontal,
          .vjs-volume-panel,
          .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active,
          .vjs-volume-panel.vjs-volume-panel-horizontal:hover,
          .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal,
          .vjs-volume-panel:hover,
          .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-horizontal {
            width: 3em;
        }

      .vjs-volume-level:before {
        font-size: 1em;
    }

      .vjs-volume-panel .vjs-volume-control {
        opacity: 1;
        width: 100%;
        height: 100%;
    }

      .vjs-volume-bar {
        background-color: transparent;
        margin: 0;
    }

      .vjs-slider-horizontal .vjs-volume-level {
        height: 100%;
    }

      .vjs-volume-bar.vjs-slider-horizontal {
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
    }

      .vjs-volume-bar:before {
        content: "";
        z-index: 0;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-left: 3em solid transparent;
        border-bottom: 2em solid rgba(115,133,159,.5);
        border-right: 0 solid transparent;
        border-top: 0 solid transparent;
    }

      .vjs-volume-level {
        overflow: hidden;
        background-color: transparent;
    }

          .vjs-volume-level:before {
            content: "";
            z-index: 1;
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            left: 0;
            border-left: 3em solid transparent;
            border-bottom: 2em solid var(--vjs-theme-myportal3e--secondary);
            border-right: 0 solid transparent;
            border-top: 0 solid transparent;
        }

      .vjs-progress-control:hover .vjs-progress-holder {
        font-size: 1em;
    }

      .vjs-play-progress:before {
        display: none;
    }

      .vjs-progress-holder {
        border-radius: 0.2em;
        height: 0.5em;
        margin: 0;
    }

      .vjs-load-progress,
      .vjs-load-progress div,
      .vjs-play-progress {
        border-radius: 0.2em;
    }

      .vjs-play-progress {
        background-color: var(--vjs-theme-myportal3e--secondary);
    }

      .vjs-remaining-time *,
      .vjs-icon-placeholder {
        color: var(--vjs-theme-myportal3e--secondary);
    }

      .vjs-control:focus, .video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before {
        text-shadow: none;
    }
}
