//@import './fonts/Axiforma/stylesheet.css';
@import "~@fontsource/roboto-mono/latin.css";

body.page-index {
    background-color: unset;
    overflow-x: hidden;
    margin: 0;
    min-height: 100%;
    font-family: 'Roboto Mono', sans-serif;
}

a {
    cursor: pointer;
}

ul {
    padding: 0;
    margin: 0;
}

.page-index {

.CoopImg1
{
    height:200px;
    margin-bottom: 20px;
}


.mymore
{
 width:30%;
}
.mymore h2
{
    font-size:65px!important;
}
.mymore h3
{
    font-size:16px!important;
}

/* ******************** */
/* Mobile-first         */
/* ******************** */

.row {
    display: flex;
    flex-wrap: wrap;
}

.d-block {
    display: block;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 2rem;
    font-family: 'Axiforma', sans-serif;
}

h1 {
    font-size: 2.4rem;
    font-weight: 800;
}

    h1 > small {
        margin-top: 1.33rem;
        font-size: .6em;
    }

h2 {
    font-size: 0.9rem;
    letter-spacing:2px;
    text-transform: uppercase;
}

h3 {
    font-size: 2.33rem;
    font-weight: 800;
    line-height: 1.25;
}

h4 {
    font-size: 1.6rem;
    line-height: 1.25;
}

h5 {
    font-size: 1.33rem;
}

h6 {
    margin-bottom: .67rem;
    font-size: 1.1rem;
    font-weight: 800;
}

p {
    margin-bottom: 1rem;
    font-size: .875rem;
    line-height: 1.5;
}

    p:last-of-type {
        margin-bottom: 0;
    }

a {
    text-decoration: none;
}

strong {
    font-family: 'Axiforma', sans-serif;
    font-weight: 800;
}

.bg-white {
    background-color: #ffffff;
    color: #0040bf;
}

    .bg-white * {
        color: black;
    }

.bg-blue {
    background-color: #61D194;
    color: #ffffff;
}

    .bg-blue * {
        color: #ffffff;
    }

.bg-dark-blue {
    background-color: #fff;
    color: #ffffff;
}

.bg-blue * {
    color: #ffffff;
}

.font-weight-800 {
    font-weight: 800;
}

.text-green,
.text-green * {
    color: #00d191 !important;
}
.decal_top
{
    margin-top:25px;
    margin-bottom:10px!important;
}

.text-red,
.text-red * {
    color: white !important;
}

.text-white,
.text-white * {
    color: #ffffff !important;
}

.card {
    position: relative;
    border: solid .1rem #d3ddf3;
    border-radius: 1.67rem;
    padding: 1rem;
    background: linear-gradient(to bottom right, #61D194, #fcfdfe);
    color: black;
    font-size: .9rem;
}

.container {
    margin: 0 auto;
    padding: 0.33rem;
}

section {
    position: relative;
}

header {
    background-color: white!important;
}

    header .row:nth-of-type(1) {
        align-items: center;
    }

        header .row:nth-of-type(1) > .col {
            margin-bottom: .67rem;
            min-width: 50%;
        }

            header .row:nth-of-type(1) > .col:nth-of-type(2) {
                display: flex;
                align-items: center;
                position: relative;
            }

.logo {
    height: 3.4rem;
    max-width: 100%;
    padding: 0.2rem 0;
}

.login-button {
    display: inline-block;
    border: solid .15rem #61D194;
    border-radius: 6.67rem;
    padding: .83rem 2rem .67rem;
    font-family: 'Axiforma', sans-serif;
    font-size: .625rem;
    font-weight: 800;
    text-transform: uppercase;
    transition: background-color .25s ease-in-out;
    background-color: white;
    color:black;
}

    .login-button:hover {
        background-color: #61D194;
        color:white;

    }

section,
footer {
    padding: 2rem 0;
}

#section-myportal3e {
    text-align: center;
}

    #section-myportal3e .video-container {
        position: relative;
        padding: calc(100% * 9 / 16) 0 0;
    }

        #section-myportal3e .video-container > * {
            z-index: 1;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            margin: 0 auto;
            height: 100%;
            width: 100%;
        }

    #section-3e h4 {
        font-weight: 800;
    }

    #section-3e .row .col:nth-of-type(2) .preview-container img {
        max-width: 100%;
    }

#section-features {
    text-align: center;
}

    #section-features h4:last-of-type:before {
        content: '';
        display: block;
        margin: 0 auto 1.33rem;
        height: 2rem;
        width: 3.33rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        //background-image: url('../img/page-index/plus.svg');
    }

    #section-features .card h5:before {
        content: '';
        display: block;
        margin: 0 auto 1.33rem;
        height: 3.33rem;
        width: 3.33rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

#section-accessibility {
    background-image: url('../img/page-index/accessibilite-expoll.jpg');
    background-position: 0;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

    #section-accessibility h4:before {
        content: '';
        display: block;
        margin: 0 auto .67rem;
        height: 3.33rem;
        width: 3.33rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    #section-accessibility #everywhere h4:before {
        background-image: url('../img/page-index/Lieu.svg');
    }

    #section-accessibility #internationalization h4:before {
        background-image: url('../img/page-index/langues.svg');
    }

    #section-accessibility #security h4:before {
        background-image: url('../img/page-index/cadenas.svg');
    }

    #section-accessibility > .container > .row > .col {
        margin-bottom: 2rem;
    }

#section-more {
    text-align: center;
}

    #section-more > .container > .row > .col {
        flex-grow: 1;
        padding: 0 3.33rem;
    }

.number {
    display: block;
    font-family: 'Axiforma', sans-serif;
    font-size: 3.5rem;
    font-weight: 800;
    text-transform: uppercase;
}

    footer h6 {
        color: #61D194;
    }

    footer p,
    footer ul,
    footer a {
        color: #001235;
        font-size: .8rem;
    }

    footer ul {
        line-height: 1.2rem;
    }

    footer a:hover {
        text-decoration: underline;
    }

    footer .logo {
        margin-top: -.33rem;
    }

    footer > .container > .row > .col {
        flex-grow: 1;
        padding: 1.33rem;
    }

.scroll {
    display: none;
}

.scroll-to-top {
    opacity: 0;
    visibility: hidden;
    transition: visibility .25s, opacity .25s ease-in-out;
    display: block;
    z-index: $z-index-999;
    position: fixed;
    bottom: 2rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 2rem;
    width: 2rem;
    background-image: url('../img/page-index/scroll_to_top.svg');
    background-repeat: no-repeat;
    background-size: 200%;
}

    .scroll-to-top.active {
        opacity: 1;
        visibility: visible;
    }

    .scroll-to-top:after {
        opacity: 0;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background-image: url('../img/page-index/scroll_to_top.svg');
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: 200%;
        transition: opacity .25s ease-in-out;
    }

    .scroll-to-top:hover:after {
        opacity: 1;
        transition: opacity .25s ease-in-out;
    }

/* ******************** */
/* Large screens        */
/* ******************** */

@media (min-width: 992px) {
    h1 {
        margin-bottom: 4rem;
        font-size: 2.6rem;
    }

        h1 > small {
            margin-top: 2rem;
        }

    h3 {
        font-size: 3.5rem;
        line-height: 1.25;
    }

    h4 {
        font-size: 2.33rem;
        line-height: 1.25;
    }

    h5 {
        font-size: 1.6rem;
    }

    h6 {
        font-size: 1.33rem;
    }

    .container {
        padding: 0;
        max-width: 1366px;
    }

        header .row:nth-of-type(1) > .col:nth-of-type(1) {
            padding-left: calc(100% / 12);
        }

        header .row:nth-of-type(1) > .col:nth-of-type(2) {
            display: flex;
            justify-content: flex-end;
            padding-right: calc(100% / 12);
        }

        header .row:nth-of-type(2) {
            justify-content: flex-end;
        }

    section,
    footer {
        padding: 6.67rem 0;
    }

        section > .container:after {
            content: '';
            display: block;
            z-index: 1;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 1366px;
            background-repeat: no-repeat;
            background-size: contain;
        }

    #section-myportal3e {
        padding-bottom: 10rem;
        padding-top: 2rem;
    }

        #section-myportal3e .video-container {
            position: relative;
            padding: 28rem 0 0;
        }

            #section-myportal3e .video-container > * {
                max-height: 28rem;
                max-width: calc(28rem * 16 / 9);
            }

            #section-myportal3e .video-container:before,
            #section-myportal3e .video-container:after {
                content: '';
                position: absolute;
                top: 0;
                margin: 0 auto;
                height: 100%;
                width: 24rem;
                background-image: url('/img/page-index/motif_video.svg');
                background-position: right center;
                background-repeat: no-repeat;
                background-size: auto 55%;
            }

            #section-myportal3e .video-container:before {
                left: calc(-24rem - 28rem * 16 / 9);
                right: 0;
            }

            #section-myportal3e .video-container:after {
                left: 0;
                right: calc(-24rem - 28rem * 16 / 9);
                transform: rotate(180deg);
            }

        #section-myportal3e > .container:after {
            bottom: calc(-197.93px / 2);
            height: 197.93px;
            background-image: url('../img/page-index/expoll_transition1+3.svg');
        }

    #section-3e {
        padding-bottom: 10rem;
        padding-top: 10rem;
    }

        #section-3e > .container:after {
            bottom: calc(-197.97px / 2);
            height: 197.97px;
            background-image: url('../img/page-index/expoll_transition2.svg');
        }

        #section-3e .row .col {
            width: 50%;
        }

            #section-3e .row .col:nth-of-type(1) {
                padding-left: calc(100% / 6);
                padding-right: 1.33rem;
            }

            #section-3e .row .col:nth-of-type(2) {
                padding-left: 1.33rem;
            }

                #section-3e .row .col:nth-of-type(2) .preview-container {
                    padding-left: calc(100% / 6);
                }

                    #section-3e .row .col:nth-of-type(2) .preview-container img {
                        max-width: 130%;
                    }

    .col-3 {
        width:29%;
        margin:20px;
        text-align: center;
        size:10px;
        color:black;
    }

    #section-cooperation {
        padding-bottom: 10rem;
        padding-top: 10rem;
        background-position: calc(50% + 35vw) top;
        background-repeat: no-repeat;
        background-size: auto 100%;
    }

        #section-cooperation > .container:after {
            bottom: calc(-197.93px / 2);
            height: 197.93px;
            background-image: url('../img/page-index/expoll_transition1+3.svg');
        }

        #section-cooperation > .container > .row > .col {
            padding-left: calc(100% / 6);
            padding-right: 1.33rem;
            width: calc(100% / 1.5);
        }

            #section-cooperation > .container > .row > .col > .row > .col {
                width: 50%;
            }

                #section-cooperation > .container > .row > .col > .row > .col:nth-of-type(1) {
                    padding-right: 1.33rem;
                }

    #section-features {
        padding-bottom: 10rem;
        padding-top: 10rem;
    }

        #section-features > .container:after {
            bottom: calc(-197.97px / 2);
            height: 197.97px;
            background-image: url('../img/page-index/expoll_transition4.svg');
        }

        #section-features h4 {
            margin-bottom: 4rem;
        }

            #section-features h4:not(:first-of-type) {
                margin-top: 4rem;
            }

        #section-features .card {
            height: 100%;
        }

        #section-features > .container > .row {
            padding: 0 calc(100% / 12);
        }

            #section-features > .container > .row > .col {
                padding: 0 1.33rem;
                width: 25%;
            }

    #section-accessibility {
        padding-top: 10rem;
    }

        #section-accessibility > .container > .row {
            padding: 0 calc(100% / 12);
        }

            #section-accessibility > .container > .row > .col {
                padding: 0 1.33rem;
                width: calc(100% / 3);
            }

                #section-accessibility > .container > .row > .col > p {
                    padding: 0 3.33rem;
                }

        #section-more h4 {
            margin-bottom: 4rem;
        }

        #section-more > .container > .row {
            padding: 0 calc(100% / 12);
        }

            #section-more > .container > .row > .col {
                padding: 0 1.33rem;
            }

        footer > .container > .row > .col {
            padding: 0 1.33rem;
        }

            footer > .container > .row > .col:last-of-type > ul {
                -webkit-column-count: 3;
                -moz-column-count: 3;
                column-count: 3;
            }

    .scroll {
        display: block;
        z-index: $z-index-999;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
    }

        .scroll a {
            display: inline-block;
            padding: .4rem .8rem;
        }

            .scroll a:before {
                content: ' ';
                white-space: pre;
                display: inline-block;
                border-right: solid .2rem #e6e6e6;
                padding: .4rem .8rem;
                width: 2rem;
            }

            .scroll a > span {
                display: none;
                color: #61D194!important;
            }

            .scroll a:hover > span {
                display: inline;
                color: black !important;
            }

        .scroll li:nth-of-type(1) a.active:before,
        .scroll li:nth-of-type(3) a.active:before,
        .scroll li:nth-of-type(4) a.active:before {
            border-color: #61D194;
        }

        .scroll li:nth-of-type(1) a.active:hover > span,
        .scroll li:nth-of-type(3) a.active:hover > span,
        .scroll li:nth-of-type(4) a.active:hover > span {
            color: #61D194;
        }

        .scroll li:nth-of-type(2) a.active:before,
        .scroll li:nth-of-type(5) a.active:before {
            border-color: black;
        }

        .scroll li:nth-of-type(2) a.active:hover > span,
        .scroll li:nth-of-type(5) a.active:hover > span {
            color: #003fbf;
        }
}

@media (min-width: 1366px) {
    #section-myportal3e > .container:after {
        bottom: calc(-197.93px / 2);
        padding-bottom: 197.93px;
    }
}
}
