.alert {
  padding: 1.5rem;
  color: white;
  opacity: 1;
  transition: opacity 0.6s;
  position: relative;
  margin-bottom: 1rem;

  &__closebtn {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 1rem;
  }

  &__closebtn:hover {
    color: black;
  }

  &--danger {
    background-color: #f44336;
  }
  &--info {
    background-color: rgb(10, 106, 182);
  }
}

