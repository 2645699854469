.page_enqueteur_enqueteur_new {
  .link:hover {
    cursor:pointer;
  }
  #meteo_dh
  {
    text-align:center;
  }
  img.Play {
    width: 2.375rem;
    height: 2.375rem;
    margin: 0.438rem 0 0 1.25rem;
    object-fit: contain;
  }

  .home-input
  {
    background-color:var(--green);
  }
  .home-text
  {
    //width: 11.063rem;
    //height: 1.238rem;
    font-size: 0.938rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--blue);

  }
  .home-text2
  {
    width: 2.25rem;
    height: 0.875rem;
    margin: 0 8.375rem 0.563rem 0;
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #232323;
  }
  .home-text3
  {
    //margin: 0 8.375rem 0.563rem 0;
    font-size: 0.975rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #232323;
    //text-align:center;
  }
  .home-button
  {
    width: 8.813rem;
    height: 2.5rem;
    padding: 0.813rem 3.125rem 0.75rem;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(97, 209, 148, 0.5);
    background-color: var(--green);
    color:white;
    border:0px;
  }

  .home-title
  {
    width: 10.75rem;
    height: 1rem;
    margin: 0 1.438rem 1.25rem 0.188rem;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--green);
  }

  .home-input1
  {
    width: 80%;
    height: 2.188rem!important;
    margin: 0 8.375rem 0.563rem 0;
    padding: 0.188rem 0.563rem 0.188rem 0.688rem;
    border-radius: 5px;
    border: solid 1px #d7f3e4;
    background-color: #fff;
    font-size: 0.938rem;
  }
  .home-input2
  {
    width: 80%;
    height: 2.188rem!important;

    border-radius: 5px;
    border: solid 1px #d7f3e4;
    background-color: #fff;
    font-size: 0.938rem;
  }

  .home-hr {
  // height: 0.125rem;
    border: solid 0.5px var(--green);
    margin-top:20px;
    margin-bottom:20px;
  }
}
