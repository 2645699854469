.content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 4.5rem;
  width: calc(100% - var(--sidebar-width));
  position: fixed;
  z-index: $z-index-1000;
  box-shadow: 20px 0px 20px 0px rgba(97, 209, 148, 0.5);
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);

  &__right {
    display: flex;
    align-items: center;
  }
}

.content_header_profil {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 3rem;
  position: relative;
}

.content_header_profil__icon {
  margin-right: 0.5rem;
}

.content_header_profil__text {
  display: flex;
  flex-direction: column;
}

.content_header_profil-nom {
  font-weight: bold;
}

.content_header_profil:hover .content_header_right__icon {
  fill: #61d194;
}

.content_header_profil:hover .content_header_right__text,
.content_header_profil a:hover {
  color: #61d194;
  text-decoration: none;
}

.menu_dropdown_container {
  display: none;
  padding-top: 0.7rem;
  position: absolute;
  right: 0;
  top: 100%;
}

.menu_dropdown {
  display: none;
  flex-direction: column;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  a,
  span {
    display: inline-block;
    margin: 0.5rem 2.3rem;
    font-size: 0.938rem;
    white-space: nowrap;
  }

  span {
    color: grey;
  }

  a {
    color: inherit;
  }

  a:hover {
    display: inline-block;
    margin: 0.5rem 2.3rem;
    font-size: 0.938rem;
    color: #61d194;
    white-space: nowrap;
  }
}

.content_header_profil:hover > .menu_dropdown_container {
  display: block;
}

.content_header_profil:hover .menu_dropdown {
  display: flex;
}

@media (max-width: 640px) {
  .content_header {
    &_profil {
      display: none;
    }
  }
}
